"use client";
import React, { useEffect, useState, useRef } from 'react'
import '../../styles/title_block.css'
import FeedbackFormComponent from '../forms/feedback.js'

function Home({ openModal }) {

    const [isSafari, setIsSafari] = useState(false);
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.playbackRate = 2;
        }

        if (typeof window !== "undefined") {
            setIsSafari(
                /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
                /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) &&
                !window.MSStream
            );
        }
    }, []);

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent /> });
    };


    return (
        <div className='title_block'>

            <div className='tb-header'>
                <div className='tb-left'>
                    <label className='slogan'>
                        ВСЕ МУЗЫКАЛЬНОЕ - ПРОСТО!
                    </label>
                    <img className='big-logo' src='/imgs/images/loog.replacedTEXT.png' alt='' />
                    <p className='title-block-description'>
                        Мы сделаем Вашу музыку популярной!
                    </p>
                    <p className='second-description'>
                        Ответьте всего на <b>3 вопроса и</b><br /> получите расчёт стоимости
                    </p>
                    <div
                        style={{
                            maxWidth: '250px',
                            fontSize: '15px',
                            fontWeight: '700',
                            padding: '25px 15px',
                        }}
                        className='header-paybtn btn arrowAnim'
                        onClick={handleOpenModal}
                    >Заполнить бриф</div>
                </div>
                <div className='tb-right'>
                    {/* <img className='big-phone' src='./imgs/images/main_phone.cef17d77.png' alt='' /> */}
                    {!isSafari && (
                        <video
                            ref={videoRef}
                            autoPlay
                            loop
                            muted
                            playsInline
                            className='tb-video'
                        >
                            <source src='./imgs/phone.webm' type="video/webm" />
                        </video>
                    )}
                </div>
            </div >
            <div className='tb-bottom'>
                <div className='tb-b-elem'>
                    <div className='tb-b-elem tb-b-elem-child'>
                        <img className='tb-b-elem-img' src='/imgs/images/time.88afad82.png' alt='' />
                        <label className='tb-b-elem-label'>Время</label>
                    </div>
                    <div className='tb-b-elem-description'>Экономим Ваше время, предоставляем отчёт через 24 часа после завершения компании</div>
                </div>
                <div className='tb-b-elem'>
                    <div className='tb-b-elem tb-b-elem-child'>
                        <img className='tb-b-elem-img' src='/imgs/images/strategy.5f71ddd9.png' alt='' />
                        <label className='tb-b-elem-label'>Стратегия</label>
                    </div>
                    <div className='tb-b-elem-description'>Разрабатываем индивидуальное предложение к каждому клиенту</div>
                </div>
                <div className='tb-b-elem'>
                    <div className='tb-b-elem tb-b-elem-child'>
                        <img className='tb-b-elem-img' src='/imgs/images/data_analysis.1f68da00.png' alt='' />
                        <label className='tb-b-elem-label'>Анализ</label>
                    </div>
                    <div className='tb-b-elem-description'>Анализируем результаты рекламных кампаний, предлагаем дальнейший вектор развития проекта
                    </div>
                </div>
            </div>
            <div className='arrowBlock'>
                <img className='arrowAnim2' src='/imgs/images/arrow_down.db4063d1.png' alt='' />
            </div>
        </div >
    );
}

export default Home;