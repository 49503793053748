import React, { useState, useEffect } from 'react'
import "../../styles/cases.css"
import FeedbackFormComponent from '../forms/feedback.js'


const ImageSlider = ({ images }) => {

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000);

        return () => clearInterval(interval);
    }, [images.length]);

    return (
        <div className="image-slider">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`image-container ${index === currentIndex ? 'active' : ''}`}
                    style={{ backgroundImage: `url(${image.src})` }}
                />
            ))}
        </div>
    );
};



function CasesOld({ openModal }) {

    const images = [
        { src: '../../imgs/artists/1 (4).png', alt: 'Image 1' },
        { src: '../../imgs/artists/2.png', alt: 'Image 2' },
        { src: '../../imgs/artists/3.png', alt: 'Image 3' },
        { src: '../../imgs/artists/4.png', alt: 'Image 4' },
        { src: '../../imgs/artists/6.png', alt: 'Image 6' },
        { src: '../../imgs/artists/7 (1).png', alt: 'Image 7' },
        { src: '../../imgs/artists/8.png', alt: 'Image 8' },
        { src: '../../imgs/artists/9.png', alt: 'Image 9' },
        { src: '../../imgs/artists/10.png', alt: 'Image 10' },
        { src: '../../imgs/artists/11.png', alt: 'Image 11' },
        { src: '../../imgs/artists/12.png', alt: 'Image 12' },
        { src: '../../imgs/artists/13.png', alt: 'Image 13' },
        { src: '../../imgs/artists/14.png', alt: 'Image 14' },
        { src: '../../imgs/artists/15.png', alt: 'Image 15' },
        { src: '../../imgs/artists/16.png', alt: 'Image 16' },
        { src: '../../imgs/artists/17.png', alt: 'Image 17' },
        { src: '../../imgs/artists/18.png', alt: 'Image 18' },
        { src: '../../imgs/artists/19.png', alt: 'Image 19' },
        { src: '../../imgs/artists/20.png', alt: 'Image 20' },
        { src: '../../imgs/artists/21.png', alt: 'Image 21' }
    ];

    const handleOpenModal = () => {
        openModal({ children: <FeedbackFormComponent /> });
    };

    return (
        <div className='cases'>
            <div className='cases-left-side'>
                <div className='cases-label'>
                    <div className='cl-first'>НАШИ</div>
                    <div className='cl-sec'>КЕЙСЫ</div>
                    <div className='cl-logo-label'>GORDEEV MEDIA</div>
                </div>
                <div className='cases-description'>
                    <div className='cd-text'>Мы реализовали</div>
                    <div className='cd-text cd-text-custom'>более 500</div>
                    <div className='cd-text'>успешных проектов</div>
                </div>
                <div className='cases-addictional'>
                    <div onClick={handleOpenModal} className='btn btn-ca'>Заполнить бриф</div>
                    <div className='ca-description'>Продвинем Вашу музыку<br />в топы музыкальных чартов!</div>
                </div>
            </div>
            <div className='cases-right-side'>
                <ImageSlider images={images} />
            </div>
        </div>
    )
}

const Cases = () => {

    const cases = [{ name: "Sony Music", img: "/artists/Sony.png" },
    { name: "Universal Music", img: "/artists/Universal.png" },
    { name: "ZION MUSIC", img: "/artists/ZION.png" },
    { name: "25/17", img: "/artists/2517.png" },
    { name: "Леонид Агутин", img: "/artists/Агутин.png" },
    { name: "Ольга Бузова", img: "/artists/Бузова.png" },
    { name: "ГУДЗОН", img: "/artists/ГУДЗОН.png" },
    { name: "Юлианна Караулова", img: "/artists/Караулова.png" },
    { name: "Валерий Леонтьев", img: "/artists/Леонтьев.png" },
    { name: "Лолита Милявская", img: "/artists/лолитп.png" },
    { name: "Молодой Платон", img: "/artists/молодой платон.png" },
    { name: "Руки Вверх!", img: "/artists/рукивверх.png" },
    { name: "Ольга Серябкина", img: "/artists/Серябкина.png" },
    { name: "Слава КПСС", img: "/artists/СлаваКПСС.png" },
    { name: "ALEX & RUS", img: "/artists/ALEX & RUS.png" },
    { name: "Artik & Asti", img: "/artists/ArtikAsti.png" },
    { name: "Billie Eilish", img: "/artists/Bellie Eilish.png" },
    { name: "blago white", img: "/artists/blago white.png" },
    { name: "FINIK FINYA", img: "/artists/FINIK FINYA.png" },
    { name: "Gleba TV", img: "/artists/GlebaTcv.png" },
    { name: "GUMA", img: "/artists/guma.png" },
    { name: "Ida Galich", img: "/artists/IdaGalich.png" },
    { name: "Jah Khalib", img: "/artists/JahKhalib.png" },
    { name: "LOC DOG", img: "/artists/LOCDOG.png" },
    { name: "Merab Amzoevi", img: "/artists/merabAmzoevi.png" },
    { name: "MUJUICE", img: "/artists/MUJUICE.png" },
    { name: "PIZZA", img: "/artists/pizza.png" },
    { name: "WEEKND", img: "/artists/Weeknd.png" },
    { name: "MZLFF", img: "/artists/мазелоф.png" },
    { name: "BOOKER", img: "/artists/букер.png" },
        // { name: "ZOLOTO", img: "/artists/zoloto.png" }
    ]




    return (
        <div className='cases-block'>
            <h1>Наши кейсы</h1>
            <div className="cases">
                {cases.map((item, index) => (
                    <div key={index} className="case">
                        <div className="case-name">{item.name}</div>
                        <img src={item.img} alt={item.name} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Cases